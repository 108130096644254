.input-container {
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.input {
  height: 2rem;
  font-family: var(--font-family);
  font-size: 1.5em;
  text-align: center;
  border-radius: 15px;
  border: none;
  transition: box-shadow var(--transition), background-color var(--transition);
  color: var(--text-color);
  background-color: var(--input-color);
}

.input:focus {
  outline: none;
  border: none;
  background-color: var(--input-focus-color);
  box-shadow: 3px 3px 4px var(--input-focus-color);
}

::placeholder {
  color: var(--input-placeholder-color);
}
