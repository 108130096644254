.button-add {
  align-self: center;
  width: 3em;
  height: 3em;
  border: 1px solid var(--add-button-hover-color);
  border-radius: 50%;
  font-weight: 700;
  background-color: var(--add-button-color);
  transition: background-color var(--transition);
}

.button-add:hover {
  background-color: var(--add-button-hover-color);
}
