.remove-button {
  width: 2em;
  height: 2em;
  font-weight: 700;
  background-color: var(--remove-button-color);
  border: 1px solid var(--remove-button-hover-color);
  border-radius: 50%;
  transition: background-color var(--transition);
}

.remove-button:hover {
  background-color: var(--remove-button-hover-color);
}
